/* WebKit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:hover + ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #8888887e transparent;
}

*::-moz-scrollbar-thumb:hover {
  scrollbar-color: #555 #f1f1f1;
}
